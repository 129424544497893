import request from '@/utils/request'

export function getHomeNews() {
    return request({
        url: '/ich/news/pc/home/news',
        method: 'get',
    })
}

// 获得资讯分页
export function getNewsPage(query) {
    return request({
        url: '/ich/news/page',
        method: 'get',
        params: query
    })
}

// 获得资讯分页
export function getNewsDetail(query) {
    return request({
        url: '/ich/news/get',
        method: 'get',
        params: query
    })
}