<template>
    <div class="news-detail-container" v-if="detail">
        <breadcrumb :current="{ title: detail.title }"></breadcrumb>
        <div class="news-title">
            {{ detail.title }}
        </div>
        <div class="news-info">
            <span>{{ formatDate(detail.createTime, 'yyyy-MM-dd') }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>{{ detail.origin }}</span>
            <!-- <div class="share">
                分享到微信<my-share></my-share>
            </div> -->
        </div>
        <txt-view class="txt" :txt="detail.txt"></txt-view>
        <hr/>
        <!-- {{ $route}} -->
        <comment :id="$route.params.id" :contentType="$route.meta.contentType" />
    </div>
</template>

<script>
import {
    getNewsDetail
} from "@/api/news/news";
export default {
    name: 'newsDetail',
    data() {
        return {
            detail: {},//资讯详情
        }
    },
    created() {
        // console.log(this.$route.meta.contentType);
        this.getDetail()
    },
    methods: {
        getDetail() {
            getNewsDetail({
                id: this.$route.params.id
            }).then(res => {
                console.log(res)
                if (res.code == 0) {
                    this.detail = res.data
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.news-detail-container {
    width: 1200px;
    margin: 0 auto;

    .news-title {
        width: 100%;
        height: 100px;
        font-size: 32px;
        color: #181B1C;
        display: flex;
        align-content: center;
        text-align: center;
        font-weight: bold;
    }

    .news-info {
        width: 100%;
        height: 50px;
        font-size: 14px;
        color: #979797;
        text-align: center;

        .share {
            float: right;
        }
    }
}
</style>